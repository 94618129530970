<template>
    <el-row class="warp">
        <el-col :span="24" class="warp-breadcrum">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/' }"><b>首页</b></el-breadcrumb-item>
                <el-breadcrumb-item>内容管理</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/scenery/list' }">景点</el-breadcrumb-item>
                <el-breadcrumb-item>编辑</el-breadcrumb-item>
            </el-breadcrumb>
        </el-col>
        <el-col :span="24" class="warp-main">
            <el-form ref="form" :model="form" :rules="rules" label-width="150px" class="el-form-self">

                <el-row>
                    <el-col>
                        <el-form-item label="类型:" prop="type">
                            <el-select v-model="form.type" placeholder="请选择" class="el-select-big" clearable>
                                <el-option
                                        v-for="item in typeArray"
                                        :key="item.value"
                                        :label="item.name"
                                        :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col>
                        <el-form-item label="名称:" prop="name">
                            <el-input v-model="form.name" auto-complete="off" placeholder="请输入名称" maxlength="50" show-word-limit clearable></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>


                <el-row>
                    <el-col>
                        <el-form-item label="简介:" prop="introduction">
                            <el-input type="textarea" v-model="form.introduction" auto-complete="off" show-word-limit show-word-limit placeholder="请输入景色简介" maxlength="1000" clearable></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col>
                        <el-form-item label="地址:" prop="address">
                            <el-input v-model="form.address" auto-complete="off" show-word-limit placeholder="请输入地址" maxlength="100" clearable></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>


                <el-row>
                    <el-col>
                        <el-form-item label="列表缩略图片:" class="is-required">
                            <el-upload
                                    class="homeBannerImage-uploader"
                                    :action="uploadFileURL"
                                    :show-file-list="false"
                                    :on-success="handleIndexImageSuccess"
                                    :before-upload="beforeIndexImageUpload">
                                <img v-if="indexImageUrl" :src="indexImageUrl" class="homeBannerImage">
                                <i v-else class="el-icon-plus homeBannerImage-uploader-icon"></i>
                                <div slot="tip" class="el-upload__tip">注意：点击 + 上传.jpg, .png后缀图片文件。图片尺寸为220px x 220px。</div>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col>
                        <el-form-item label="图片或者视频:" class="is-required">
                            <el-upload class="upload-demo"
                                       :action="uploadFileURL"
                                       :multiple="false"
                                       :limit="1"
                                       :on-preview="handleImagePreview"
                                       :on-success="handleImageSuccess"
                                       :before-upload="beforeImageUpload"
                                       :on-remove="handleImageRemove"
                                       :on-exceed="handleImageExceed"
                                       :file-list="imageUrlArray">
                                <el-button size="small" type="primary">点击上传</el-button>
                                <div slot="tip" class="el-upload__tip">只能上传图片和视频文件，图片尺寸为750px x 375px，且不超过5MB，视频不能超过20MB</div>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col>
                        <el-form-item label="音频:">
                            <el-upload class="upload-demo"
                                       :action="uploadFileURL"
                                       :multiple="false"
                                       :limit="1"
                                       :on-preview="handleVideoPreview"
                                       :on-success="handleVideoSuccess"
                                       :before-upload="beforeVideoUpload"
                                       :on-remove="handleVideoRemove"
                                       :on-exceed="handleVideoExceed"
                                       :file-list="videoUrlList">
                                <el-button size="small" type="primary">点击上传</el-button>
                                <div slot="tip" class="el-upload__tip">只能上传mp3文件，且不超过20MB</div>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>


                <el-row>
                    <el-col>
                        <el-form-item label="外链:" prop="outerLinkUrl">
                            <el-input v-model="form.outerLinkUrl" auto-complete="off" show-word-limit placeholder="请输入外链" maxlength="200" clearable></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>


                <el-row>
                    <el-col>
                        <el-form-item label="内容:" prop="content">
                            <Editor id="tinymce" v-model="form.content" :init="editorInit"></Editor>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col>
                        <el-form-item label="置顶:" prop="isTop">
                            <el-radio-group v-model="form.isTop" placeholder="请选择置顶">
                                <el-radio label="0">是</el-radio>
                                <el-radio label="1">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col>
                        <el-form-item label="推荐:" prop="recommended">
                            <el-radio-group v-model="form.recommended" placeholder="请选择推荐">
                                <el-radio label="0">是</el-radio>
                                <el-radio label="1">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row class="form-buttons">
                    <el-form-item class="el-form-item-self">
                        <el-button type="primary" @click="$router.go(-1)">返回</el-button>
                        <el-button type="primary" @click="onSubmit">保存</el-button>
                        <el-button type="primary" @click="temporarySubmit">发布</el-button>
                    </el-form-item>
                </el-row>
            </el-form>

            <el-dialog :visible.sync="imageUrlDialogVisible" >
                <video v-if="uploadType==='video/mp4'" v-for="(video,index) in imageUrlList" :key="index" width="100%" height="100%" :src="downloadFileURL+video+'/'" controls></video>
                <el-image v-if="uploadType!=='video/mp4'" v-for="(image,index) in imageUrlList" :key="index"  style="width: 200px; height: 200px" :src="downloadFileURL+image+'/'" alt="" :preview-src-list="imageUrls"></el-image>
            </el-dialog>

            <el-dialog :visible.sync="videoUrlDialogVisible">
                <audio v-if="form.videoUrl" :src="downloadFileURL+form.videoUrl+'/'" controls/>
            </el-dialog>
        </el-col>
    </el-row>

</template>
<script>
    import {reqEditScenery,reqGetSceneryOne} from '../../../api/scenery-api'
    import {downloadFileURL, uploadFileURL} from '../../../api/system-api'
    import {SERVER_HOST} from '../../../common/portConfig'
    import tinymce from 'tinymce/tinymce'
    import 'tinymce/themes/silver/theme'
    import Editor from '@tinymce/tinymce-vue'
    import 'tinymce/plugins/code'
    import 'tinymce/plugins/print'
    import 'tinymce/plugins/preview'
    import 'tinymce/plugins/importcss'
    import 'tinymce/plugins/searchreplace'
    import 'tinymce/plugins/autolink'
    import 'tinymce/plugins/autosave'
    import 'tinymce/plugins/save'
    import 'tinymce/plugins/directionality'
    import 'tinymce/plugins/visualblocks'
    import 'tinymce/plugins/visualchars'
    import 'tinymce/plugins/fullscreen'
    import 'tinymce/plugins/image'
    import 'tinymce/plugins/media'
    import 'tinymce/plugins/link'
    import 'tinymce/plugins/codesample'
    import 'tinymce/plugins/table'
    import 'tinymce/plugins/charmap'
    import 'tinymce/plugins/hr'
    import 'tinymce/plugins/pagebreak'
    import 'tinymce/plugins/nonbreaking'
    import 'tinymce/plugins/anchor'
    import 'tinymce/plugins/toc'
    import 'tinymce/plugins/insertdatetime'
    import 'tinymce/plugins/advlist'
    import 'tinymce/plugins/lists'
    import 'tinymce/plugins/wordcount'
    import 'tinymce/plugins/imagetools'
    import 'tinymce/plugins/textpattern'
    import 'tinymce/plugins/noneditable'
    import 'tinymce/plugins/help'
    import 'tinymce/plugins/charmap'
    import 'tinymce/plugins/quickbars'
    import 'tinymce/plugins/lists'
    import 'tinymce/plugins/media'
    import 'tinymce/plugins/indent2em'
    import 'tinymce/icons/default/icons'
    import '../../../../public/static/tinymce/langs/zh_CN.js'

    export default {
        components: {
            Editor
        },
        data() {
            return {
                address:'',
                imageUrls:[],
                indexImageUrl:'',
                typeArray:[
                    {name:'宗教景点',value:'1'},
                    {name:'人文景点',value:'2'},
                    {name:'网红景点',value:'3'},
                    {name:'周边景点',value:'4'},
                ],
                videoUrlDialogVisible:false,
                imageUrlDialogVisible:false,
                imageUrl:'',
                imageUrlArray:[],
                imageUrlList:[],
                uploadType:'',
                uploadFileURL: uploadFileURL,
                downloadFileURL: downloadFileURL,
                editPath: '/scenery/list',
                defaultMsg: '',
                videoUrlList: [],
                form: {
                    outerLinkUrl:'',
                    indexImageUrl:'',
                    imageUrl:'',
                    name:'',
                    videoUrl: '',
                    content: '',
                    status: '',
                    isTop:'',
                    recommended:'',
                    publisher: '',
                    systemUser: '',
                    videoName: '',
                },
                editorInit: {
                    language_url: '/static/tinymce/langs/zh_CN.js',
                    language: 'zh_CN',
                    skin_url: '/static/tinymce/skins/ui/oxide',
                    content_css: "/static/tinymce/skins/content/default/content.css",
                    height: 800,
                    font_formats: '微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;苹果苹方=PingFang SC,Microsoft YaHei,sans-serif;宋体=simsun,serif;仿宋体=FangSong,serif;黑体=SimHei,sans-serif;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;',
                    plugins: 'code print preview indent2em importcss searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image link codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars media',
                    toolbar: 'undo redo |code bold italic underline strikethrough indent2em| fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak charmap | fullscreen  preview print | image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
                    file_picker_types: 'media file',
                    // images_upload_url: '/editorfile/uploadFile',
                    autosave_ask_before_unload: false,
                    file_picker_callback: function (callback, value, meta) {
                        if (meta.filetype === 'media') {
                            var input = document.createElement('input');
                            input.setAttribute('type', 'file');
                            //你可以给input加accept属性来限制上传的文件类型
                            //例如：input.setAttribute('accept', '.jpg,.png');
                            input.setAttribute('accept', '.mp4');
                            input.click();
                            input.oninput = function () {
                                var file = this.files[0];

                                var xhr, formData;
                                console.log(file.name);
                                xhr = new XMLHttpRequest();
                                xhr.open('POST', SERVER_HOST + '/editorfile/uploadVideoFile');
                                // xhr.withCredentials = true ;
                                xhr.onload = function () {
                                    var json;
                                    if (xhr.status != 200) {
                                        alert("上传视频失败");
                                        return false;
                                    }
                                    json = JSON.parse(xhr.responseText);
                                    if (!json || typeof json.location != 'string') {
                                        alert("上传视频失败");
                                        return false;
                                    }
                                    callback(json.location);
                                };
                                formData = new FormData();
                                if (file.size / 1024 / 1024 > 100) {
                                    alert("上传视频不能超过100m");
                                    return false;
                                }
                                formData.append('file', file, file.name);
                                xhr.send(formData);
                            }
                        } else if (meta.filetype === 'file') {
                            var input = document.createElement('input');
                            input.setAttribute('type', 'file');
                            //你可以给input加accept属性来限制上传的文件类型
                            //例如：input.setAttribute('accept', '.jpg,.png');
                            input.setAttribute('accept', '.xls,.xlsx,.doc,.docx,.pdf');
                            input.click();
                            input.oninput = function () {
                                var file = this.files[0];

                                var xhr, formData;
                                console.log(file.name);
                                xhr = new XMLHttpRequest();
                                xhr.open('POST', SERVER_HOST + '/editorfile/uploadFile');
                                xhr.withCredentials = true;
                                xhr.onload = function () {
                                    var json;
                                    if (xhr.status != 200) {
                                        alert('上传失败');
                                        return false;
                                    }
                                    json = JSON.parse(xhr.responseText);
                                    if (!json || typeof json.data != 'string') {
                                        alert('上传失败');
                                        return false;
                                    }
                                    callback(json.data, {text: ''});
                                };
                                formData = new FormData();
                                if (file.size / 1024 / 1024 > 20) {
                                    alert('上传失败');
                                    return false;
                                }
                                formData.append('file', file, file.name);
                                xhr.send(formData);
                            }
                        }
                    },
                    images_upload_handler: function (blobInfo, succFun, failFun) {
                        var xhr, formData;
                        var file = blobInfo.blob();//转化为易于理解的file对象
                        xhr = new XMLHttpRequest();
                        xhr.open('POST', SERVER_HOST + '/editorfile/uploadFile', true);
                        xhr.withCredentials = true;
                        xhr.onload = function () {
                            var json;
                            if (xhr.status != 200) {
                                failFun('上传图片失败');
                                return;
                            }
                            json = JSON.parse(xhr.responseText);
                            if (!json || typeof json.data != 'string') {
                                failFun('上传图片失败');
                                return;
                            }
                            succFun(json.data);
                        };
                        formData = new FormData();
                        formData.append('file', file, file.name);
                        xhr.send(formData);
                    }
                },
                rules: {
                    type:[
                        {required: true, message: '请选择类型'},
                    ],
                    name:[
                        {required: true, message: '请输入名称', trigger: 'blur'},
                    ],
                    address:[
                        {required: true, message: '请输入地址', trigger: 'blur'},
                    ],
                    outerLinkUrl: [
                        {required: false, message: '请输入外链', trigger: 'blur'},
                    ],
                    indexImageUrl:[
                        {required: true, message: '请上传列表缩略图片'},
                    ],
                    isTop:[
                        {required: true, message: '请选择是否置顶'},
                    ],
                    recommended:[
                        {required: true, message: '请选择是否推荐'},
                    ],
                    content:[
                        {required: true, message: '请输入内容', trigger: 'change'},
                    ],
                },
            }
        },

        methods: {
            //-----------------图片或者视频
            beforeImageUpload(file) {
                const isValidType = (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png'||file.type==='video/mp4');
                var fileSize=5;
                if(file.type==='video/mp4'){
                    fileSize=20;
                }
                const isLt20M = file.size / 1024 / 1024 < fileSize;
                this.uploadType=file.type;
                if (!isValidType) {
                    this.$message.error('不支持该类型的文件上传。');
                    return false;
                }
                if (!isLt20M) {
                    this.$message.error('上传文件大小不能超过 '+fileSize+'MB!');
                    return false;
                }
                return true;
            },

            handleImagePreview(file) {
                var that=this;
                that.imageUrls=[];
                that.imageUrlList.forEach(function (obj) {
                    that.imageUrls.push(downloadFileURL+obj+'/')
                });
                that.imageUrlDialogVisible=true;
            },
            handleImageSuccess(response, file, fileList) {
                var that=this;
                console.log(file.size);
                if (response.code === 1) {
                    that.imageUrlList.push(response.data);

                }
            },
            handleImageRemove(file, fileList) {
                var that=this;
                var index=this.imageUrlList.indexOf(file.name);
                if(index!==-1){
                    that.imageUrlList.splice(index,1);
                    console.log(that.imageUrlList)
                }else{
                    if(file.response){
                        index = that.imageUrlList.indexOf(file.response.data);
                        that.imageUrlList.splice(index,1);
                    }
                }
            },
            handleImageExceed(files, fileList) {
                this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
            },
            //-----------------音频
            beforeVideoUpload(file) {
                const isValidType = (file.type === 'audio/mp3' || file.type === "audio/mpeg");
                const isLt20M = file.size / 1024 / 1024 < 20;

                if (!isValidType) {
                    this.$message.error('不支持该类型的文件上传。');
                    return false;
                }
                if (!isLt20M) {
                    this.$message.error('上传文件大小不能超过 20MB!');
                    return false;
                }
                return true;
            },
            handleVideoPreview(file) {
                this.videoUrlDialogVisible=true;
                console.log(file);
            },
            handleVideoRemove() {
                this.form.videoUrl='';
            },

            handleVideoSuccess(response, file, fileList) {
                console.log(file.size)
                if (response.code === 1) {
                    this.form.videoName = file.name;
                    this.form.videoUrl = response.data ;
                }
            },

            handleVideoExceed(files, fileList) {
                this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
            },


            onSubmit() {
                var that = this;
                that.form.status = '1';
                that.form.systemUser = sessionStorage.getItem('Authorization');
                delete that.form['createTime'];
                delete that.form['updateTime'];
                if (that.form.content.length > 20000) {
                    that.$message({
                        type: 'warning',
                        message: '内容不能超过20000个字符',
                    });
                    return;
                }
                that.form.imageUrl=that.imageUrlList.join(',');
                var para = Object.assign({}, that.form);
                reqEditScenery(para).then((res) => {
                    if (res.data.code === 1) {
                        that.$message({
                            message: '保存成功',
                            type: 'success'
                        });
                        that.$router.push({path: that.editPath});
                    } else {
                        that.$message({
                            message: '保存失败',
                            type: 'error'
                        });
                    }
                })
            },
            temporarySubmit() {
                var that = this;
                this.$refs.form.validate((valid) => {
                    if (valid) {
                        that.form.imageUrl=that.imageUrlList.join(',');
                        if(!that.form.imageUrl){
                            that.$message({
                                message: '请上传图片或视频',
                                type: 'warning'
                            });
                            return false;
                        }
                        if (that.form.content.length > 20000) {
                            that.$message({
                                type: 'warning',
                                message: '内容不能超过20000个字符',
                            });
                            return;
                        }

                        that.form.status = '0';
                        that.form.systemUser = sessionStorage.getItem('Authorization');
                        delete that.form['createTime'];
                        delete that.form['updateTime'];
                        var para = Object.assign({}, that.form);
                        reqEditScenery(para).then((res) => {
                            if (res.data.code === 1) {
                                that.$message({
                                    message: '发布成功',
                                    type: 'success'
                                });
                                that.$refs.form.resetFields();
                                that.$router.push({path: that.editPath});
                            } else {
                                that.$message({
                                    message: '发布失败',
                                    type: 'error'
                                });
                            }
                        })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            getSceneryOne(id){
                var that=this;
                reqGetSceneryOne({id:id}).then(res=>{
                    if(res.data.code===1){
                        that.form=res.data.data;
                        if(that.form.videoUrl){
                            that.videoUrlList=[{url:downloadFileURL+that.form.videoUrl+'/',name:that.form.videoName}]
                        }
                        if(that.form.indexImageUrl){
                            that.indexImageUrl = downloadFileURL+that.form.indexImageUrl+'/';
                        }
                        that.imageUrlList=[];
                        if(that.form.imageUrl){
                            that.imageUrlList=that.form.imageUrl.split(',');
                            that.imageUrlList.forEach(function (obj,i) {
                                that.imageUrlArray.push({url:downloadFileURL+obj+'/',name:obj});
                                if(obj.toLowerCase().indexOf('.mp4')!==-1){
                                    that.uploadType='video/mp4';
                                }
                            })
                        }
                        console.log(that.imageUrlList)
                    }else{
                        that.$message({
                            message: '查询失败',
                            type: 'error'
                        });
                    }
                }).catch(()=>{
                    that.$message({
                        message: '查询失败',
                        type: 'error'
                    });
                })
            },
            beforeIndexImageUpload(file) {
                const isValidType = (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png');
                const isLt5M = file.size / 1024 / 1024 < 5;

                if (!isValidType) {
                    this.$message.error('不支持改类型的文件上传。');
                }
                if (!isLt5M) {
                    this.$message.error('上传文件大小不能超过 5MB!');
                }
                return isValidType && isLt5M;
            },
            handleIndexImageSuccess(response) {
                if (response.code === 1) {
                    this.form.indexImageUrl = response.data;
                    this.indexImageUrl=downloadFileURL + response.data + "/";
                    this.$refs.form.validateField('indexImageUrl');
                    console.log(this.indexImageUrl)
                }
            },

        },
        mounted() {
            tinymce.init({});
            var row = this.$route.query;
            this.getSceneryOne(row.id);
        }
    }
</script>
